import s from "./StaffPopupInfo.module.scss";
import Trainer from "../../assets/images/icons/trainer.png";
import PopupInfo from "../PopupInfo/PopupInfo";
import React, {useEffect, useState} from "react";
import {backendAPI} from "../../api/backendAPI";

interface IStaffPopupInfo {
    handleUpdateTrainer: any
    handleDeleteTrainer: any
    updateData: any
    closeHandle: any
    trainerId: number
}

const StaffPopupInfo = ({handleUpdateTrainer, handleDeleteTrainer, updateData, closeHandle, trainerId}: IStaffPopupInfo) => {
    let [data, setData] = useState<any>({contact_details: {
            first_name: "",
            last_name: "",
        }});
    let [popupData, setPopupData] = useState<any>({});
    let [isLoading, setIsLoading] = useState(true);

    let fetchData = async () => {
        setIsLoading(true);
        let response = await backendAPI.fetchTrainerInfo(trainerId);
        setData(response);
        setPopupData([{
            title: "Contact information",
            items: [{title: "Phone", text: response.contact_details.phone_number},
                {title: "Email", text: response.contact_details.email},]
        },{
            title: "Monthly statistics",
            items: [{title: "Bookings completed", text: response.monthly_statistics.bookings_completed},
                {title: "Clients Worked With", text: response.monthly_statistics.clients_worked_with},
                {title: "Revenue", text: response.monthly_statistics.revenue},]
        }]);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <PopupInfo popupTitle={"Trainer"} isLoading={isLoading} title={`${data.contact_details.first_name} ${data.contact_details.last_name}`} subtitle={``} image={Trainer} data={popupData} onEdit={handleUpdateTrainer} onDelete={handleDeleteTrainer} fetchData={fetchData} closePopup={closeHandle}/>
    );
};

export default StaffPopupInfo;