import React, {useState} from 'react';
import s from './NewService.module.scss';
import Input from "../UI/Input/Input";
import Checkbox from "../UI/Checkbox/Checkbox";
import Button from "../UI/Button/Button";
import Stripe from "../../assets/images/signup/stripe.png";
import Card from "../../assets/images/signup/card.png";
import TrainerSelect from "./components/TrainerSelect/TrainerSelect";

const NewService = () => {
    return (
        <section className={s.form}>
            <div className={s.form__title}>
                <h2>Create account</h2>
            </div>
            <form action="#" className={s.form__form}>
                <TrainerSelect/>
                <Input placeholder={'Full name'} label={'First Name'}/>
                <Input placeholder={'Full name'} label={'First Name'}/>
                <Input placeholder={'Full name'} label={'First Name'}/>
                <Input placeholder={'Full name'} label={'First Name'}/>
                <Input placeholder={'Full name'} label={'First Name'}/>
                <Input placeholder={'Full name'} label={'First Name'}/>
                <div className={s.form__buttons}>
                    <Checkbox text={'Remember me'}/>
                    <Button text={'Continue'}/>
                </div>
            </form>
        </section>
    );
};

export default NewService;