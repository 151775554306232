import React, {useState} from 'react';
import s from './Day.module.scss';
import Checkbox from "../../../UI/Checkbox/Checkbox";
import Clock from "../../../../assets/images/icons/clock.svg";
import Plus from "../../../../assets/images/icons/plus_filled.svg";
import Delete from "../../../../assets/images/icons/delete.svg";
import Input from "../../../UI/Input/Input";

interface IDay {
    data: any
    schedule: any
    setSchedule: any
}
const Day = ({data, schedule, setSchedule}: IDay) => {
    let [isActive, setIsActive] = useState(false);

    let addTime = () => {
        let times = data.times;
        times.push({start: "00:00", end: "00:00"});
        setSchedule(schedule.map((d: any) => {
            if (d == data.day) {
                return {...d, times}
            }
            return d;
        }));
    };

    let deleteTime = (index: number) => {
        let times = data.times;
        times.splice(index, 1);
        setSchedule(schedule.map((d: any) => {
            if (d == data.day) {
                return {...d, times}
            }
            return d;
        }));
    };

    let changeTime = (index: number, time: "start" | "end", value: string) => {
        let times = data.times;
        times[index][time] = value;
        setSchedule(schedule.map((d: any) => {
            if (d == data.day) {
                return {...d, times}
            }
            return d;
        }));
    };

    return (
        <div className={s.day}>
            <div className={s.day__header}>
                <div className={s['day__header-info']}>
                    <Checkbox text={data.day} checked={isActive} onChange={setIsActive}/>
                </div>
            </div>
            {isActive && <div className={s.day__body}>
                <button className={s['day__body-button']} onClick={addTime} type={"button"}><img src={Plus} alt=""/></button>
                <div className={s.day__items}>
                    {data.times.map((t: any, index: number) => <div className={s['day__items-item']} key={index}>
                        <div className={s['day__items-item__inputs']}>
                            <Input isSmall={true} type={"time"} value={t.start} onChange={(e: any) => {changeTime(index, "start", e.currentTarget.value)}}/>
                        </div>
                        <span>-</span>
                        <div className={s['day__items-item__inputs']}>
                            <Input isSmall={true} type={"time"} value={t.end} onChange={(e: any) => {changeTime(index, "end", e.currentTarget.value)}}/>
                        </div>
                        <button className={s['day__items-item__delete']} onClick={() => {deleteTime(index)}} type={"button"}>
                            <img src={Delete} alt=""/>
                        </button>
                    </div>)}
                </div>
            </div>}
        </div>
    );
};

export default Day;