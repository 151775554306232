import React from 'react';
import s from './Step.module.scss';
import classNames from "classnames";

interface IStep {
    title: string
    description: string
    icon: string
    onClick: () => void
    isActive: boolean
    isPrev: boolean
}
const Step = ({title, description, icon, onClick, isActive, isPrev}: IStep) => {
    return (
        <div className={classNames(s.step, isActive && s.step_active, isPrev && s.step_prev)} onClick={onClick}>
            <div className={s.step__icon}>
                <img src={icon} alt=""/>
            </div>
            <div className={s.step__info}>
                <span>{title}</span>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default Step;