import React from 'react';
import s from './Analytics.module.scss';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Select from "../UI/Select/Select";

interface IAnalytics {
    analytics: any
}
const Analytics = ({analytics}: IAnalytics) => {
    return (
        <div className={s.analytics}>
            <div className={s.analytics__header}>
                <div className={s["analytics__header-block"]}>
                    <h2>Business Analytics</h2>
                    <span>Bookings (This Week) <strong>{analytics.total_bookings}</strong></span>
                </div>
                <div className={s['analytics__header-block']}>
                    {/*<Select isSmall={true} className={s['analytics__select']}/>*/}
                    <div className={s['analytics__header-legend']}>
                        <div className={s['analytics__header-legend__item']}>
                            <div style={{background: '#4F69C1'}}></div>
                            <span>Last week</span>
                        </div>
                        <div className={s['analytics__header-legend__item']}>
                            <div style={{background: '#D858AE'}}></div>
                            <span>Current week</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.analytics__graph}>
                <ResponsiveContainer width="99%" height="100%">
                    <LineChart
                        data={analytics.data}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" style={{fontSize: '12px'}} tick={{fill: '#97979E'}}/>
                        <YAxis axisLine={false} style={{fontSize: '12px'}} tick={{fill: '#97979E'}} width={40}/>
                        <Tooltip />
                        <Line type="monotone" dataKey="lw" name={"Last week"} stroke="#4F69C1" dot={{r: 5}} activeDot={{ r: 8 }}/>
                        <Line type="monotone" dataKey="cw" name={"Current week"} stroke="#D858AE" dot={{r: 5}} activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default Analytics;