import React, {useState} from 'react';
import s from './Sidebar.module.scss';
import Logo from "../../assets/images/logo.png";
import Dashboard from "../../assets/images/icons/dashboard.svg";
import Bookings from "../../assets/images/icons/calendar.svg";
import Staff from "../../assets/images/icons/people.svg";
import Settings from "../../assets/images/icons/settings.svg";
import Plus from "../../assets/images/icons/plus.svg";
import PlusFilled from "../../assets/images/icons/plus_filled.svg";
import DashboardFilled from "../../assets/images/icons/dashboard_filled.svg";
import BookingsFilled from "../../assets/images/icons/calendar_filled.svg";
import StaffFilled from "../../assets/images/icons/people_filled.svg";
import SettingsFilled from "../../assets/images/icons/settings_filled.svg";
import Logout from "../../assets/images/icons/logout.svg";
import classNames from "classnames";
import {ReactComponent as Arrow} from './../../assets/images/icons/arrow_big.svg';
import {NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {logout} from "../../store/actionCreators";

const Sidebar = () => {
    let [isActive, setIsActive] = useState(false);
    let authData = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const handleLogout = () => {
        dispatch(logout());
    }

    return (
        <aside className={classNames(s.sidebar, isActive && s.sidebar_active)}>
            <NavLink to={'/'} className={s.sidebar__logo}>
                <img src={Logo} alt=""/>
            </NavLink>
            <ul className={s.sidebar__menu}>
                <li className={s['sidebar__menu-item']}>
                    <NavLink to={'/'} className={({isActive}) => classNames(s['sidebar__menu-item__link'], isActive && s['sidebar__menu-item__link_active'])}>
                        {({ isActive }) => (
                            <>
                                {isActive ? <img src={DashboardFilled} alt=""/> : <img src={Dashboard} alt=""/>}
                                <span>Dashboard</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className={classNames(s['sidebar__menu-item'])}>
                    <NavLink to={'/bookings'} className={({isActive}) => classNames(s['sidebar__menu-item__link'], isActive && s['sidebar__menu-item__link_active'])}>
                        {({ isActive }) => (
                            <>
                                {isActive ? <img src={BookingsFilled} alt=""/> : <img src={Bookings} alt=""/>}
                                <span>Bookings</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className={s['sidebar__menu-item']}>
                    <NavLink to={'/staff'} className={({isActive}) => classNames(s['sidebar__menu-item__link'], isActive && s['sidebar__menu-item__link_active'])}>
                        {({ isActive }) => (
                            <>
                                {isActive ? <img src={StaffFilled} alt=""/> : <img src={Staff} alt=""/>}
                                <span>Staff</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className={s['sidebar__menu-item']}>
                    <NavLink to={'/services'} className={({isActive}) => classNames(s['sidebar__menu-item__link'], isActive && s['sidebar__menu-item__link_active'])}>
                        {({ isActive }) => (
                            <>
                                {isActive ? <img src={PlusFilled} alt=""/> : <img src={Plus} alt=""/>}
                                <span>Services</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className={s['sidebar__menu-item']}>
                    <NavLink to={'/settings'} className={({isActive}) => classNames(s['sidebar__menu-item__link'], isActive && s['sidebar__menu-item__link_active'])}>
                        {({ isActive }) => (
                            <>
                                {isActive ? <img src={SettingsFilled} alt=""/> : <img src={Settings} alt=""/>}
                                <span>Settings</span>
                            </>
                        )}
                    </NavLink>
                </li>
                {authData.data.isAuth && <li className={classNames(s['sidebar__menu-item'], s['sidebar__menu-item_logout'])}>
                    <button className={s['sidebar__menu-item__link']} onClick={handleLogout}>
                        <img src={Logout} alt=""/>
                        <span>Logout</span>
                    </button>
                </li>}
            </ul>
            <button className={s.sidebar__arrow} onClick={() => {setIsActive(!isActive)}}>
                <Arrow/>
            </button>
        </aside>
    );
};

export default Sidebar;