import React from 'react';
import s from './BookingsItem.module.scss';
import Service from "../../../../../assets/images/services/service.jpg";
import Calendar from "../../../../../assets/images/icons/calendar_filled.svg";
import Clock from "../../../../../assets/images/icons/clock.svg";
import {NavLink} from "react-router-dom";
import dayjs from "dayjs";

interface IBookingsItem {
    booking: any
}
const BookingsItem = ({booking}: IBookingsItem) => {
    return (
        <NavLink to={'/'} className={s['bookings__item']}>
            <div className={s['bookings__item-image']}>
                <img src={Service} alt=""/>
            </div>
            <div className={s['bookings__item-info']}>
                <div className={s['bookings__item-info__date']}>
                    <time>
                        <img src={Calendar} alt=""/>
                        <span>{dayjs(booking.start_time).format("DD MMM, YYYY")}</span>
                    </time>
                    <time>
                        <img src={Clock} alt=""/>
                        <span>{dayjs(booking.start_time).format("HH:mm A")}</span>
                    </time>
                </div>
                <span>{booking.event.title}</span>
                <div className={s['bookings__item-info__service']}>
                    <span>{booking.event.trainer}</span>
                    <span>Hair Color</span>
                </div>
            </div>
            <div className={s['bookings__item-price']}>
                <span>${booking.event.price}</span>
            </div>
        </NavLink>
    );
};

export default BookingsItem;