import React from 'react';
import s from './Schedule.module.scss';
import Clock from './../../assets/images/icons/clock.svg';
import Checkbox from "../UI/Checkbox/Checkbox";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Day from "./components/Day/Day";

interface ISchedule {
    schedule: any
    setSchedule: any
}
const Schedule = ({schedule, setSchedule}: ISchedule) => {
    return (
        <div className={s.schedule}>
            <p>Set a schedule below or edit and create new schedules.</p>
            <div className={s.schedule__items}>
                {schedule.map((d: any) => <Day data={d} schedule={schedule} setSchedule={setSchedule} key={d.day}/>)}
            </div>
        </div>
    );
};

export default Schedule;