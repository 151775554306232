import React from 'react';
import s from './StaffBoard.module.scss';
import Photo from './../../assets/images/icons/trainer.png';
import Edit from './../../assets/images/icons/edit.svg';
import Schedule from "./components/Schedule/Schedule";

interface IStaffBoard {
    showEditPopup: (id: number) => void
    data: any
}
const StaffBoard = ({showEditPopup, data}: IStaffBoard) => {
    let dates = [];
    let trainersSchedule = [];

    for (const key in data.weekly_schedule) {
        dates.push({day: key, date: data.weekly_schedule[key]});
    }

    for (let i = 0; i < data.trainers.length; i++) {
        let schedule = [];
        for (const key in data.trainers[i].schedule) {
            schedule.push({day: key, times: [...data.trainers[i].schedule[key]]});
        }
        trainersSchedule.push(schedule);
    }

    return (
        <div className={s.board}>
            <div></div>
            <div className={s.dates}>
                {dates.map((d: any) => (<div className={s.dates__item} key={d.day}>
                    <time>{d.day}</time>
                    <span>{d.date}</span>
                </div>))}
            </div>
            <div className={s.staff}>
                {data.trainers.map((t: any) => (<div className={s.staff__item} key={t.trainer_id}>
                    <div className={s['staff__item-info']}>
                        <img src={Photo} alt=""/>
                        <div>
                            <span>{t.trainer}</span>
                            <time>48h 30mins</time>
                        </div>
                    </div>
                    <button onClick={() => {
                        showEditPopup(t.trainer_id);
                    }}>
                        <img src={Edit} alt=""/>
                    </button>
                </div>))}
            </div>
            <div className={s.rows}>
                <div className={s.rows__empty}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {trainersSchedule.map((t: any, index: number) => (
                    <div className={s.rows__item} key={index}>
                        {t.map((d: any) => (<Schedule times={d.times} key={d.day}/>))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StaffBoard;