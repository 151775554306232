import React from 'react';
import s from './Block.module.scss';

interface IBlock {
    title: string
    children: any
}
const Block = ({title, children}: IBlock) => {
    return (
        <div className={s.block}>
            <h2>{title}</h2>
            <div className={s.block__content}>
                {children}
            </div>
        </div>
    );
};

export default Block;