import React from 'react';
import s from './ServicesBoard.module.scss';
import Photo from './../../assets/images/services/service.jpg';
import Edit from './../../assets/images/icons/edit.svg';
import classNames from "classnames";
import Button from "../UI/Button/Button";
import {backendAPI} from "../../api/backendAPI";

interface IServicesBoard {
    data: any
    showCreatePopup: () => void
    showEditPopup: () => void
    closePopup: () => void
    setEditingEventId: any
}
const ServicesBoard = ({data, showCreatePopup, showEditPopup, closePopup, setEditingEventId}: IServicesBoard) => {

    let handleEdit = (id: number) => {
        setEditingEventId(id);
        showEditPopup();
    }

    return (
        <div className={s.services}>
            <div className={s.services__header}>
                <div className={s['services__header-button']}>
                    <Button text={"+ Add Service"} onClick={showCreatePopup}/>
                </div>
                <div className={s['services__header-headings']}>
                    <div><span>Description</span></div>
                    <div><span>Length</span></div>
                    <div><span>Price</span></div>
                    <div><span>Trainers</span></div>
                    <div><span>Grouped</span></div>
                    <div><span>Scheduled</span></div>
                    <div><span>Max Clients</span></div>
                </div>
            </div>
            <div className={s.services__body}>
                <div className={classNames(s.service, s.service_empty)}>
                    <div className={s.service__info}>
                    </div>
                    <div className={s.service__stats}>
                        <div className={s['service__stats-item']}>
                        </div>
                        <div className={s['service__stats-item']}>
                        </div>
                        <div className={s['service__stats-item']}>
                        </div>
                        <div className={s['service__stats-item']}>
                        </div>
                        <div className={s['service__stats-item']}>
                        </div>
                        <div className={s['service__stats-item']}>
                        </div>
                        <div className={s['service__stats-item']}>
                        </div>
                    </div>
                </div>
                {data.events.map((e: any) => (<div className={s.service} key={e.event_id}>
                    <div className={s.service__info}>
                        <div className={s['service__info-block']}>
                            <img src={Photo} alt=""/>
                            <span>{e.title}</span>
                        </div>
                        <button><img src={Edit} alt="" onClick={() => {handleEdit(e.event_id)}}/></button>
                    </div>
                    <div className={s.service__stats}>
                        <div className={s['service__stats-item']}>
                            <span>{e.description}</span>
                        </div>
                        <div className={s['service__stats-item']}>
                            <span>{e.length / 60} mins.</span>
                        </div>
                        <div className={s['service__stats-item']}>
                            <span>${e.price / 100}</span>
                        </div>
                        <div className={s['service__stats-item']}>
                            {e.allowed_trainers.map((t: any) => (
                                <span key={t.user_id}>{t.name}</span>
                            ))}
                        </div>
                        <div className={s['service__stats-item']}>
                            <span>{e.grouped ? "Yes" : "No"}</span>
                        </div>
                        <div className={s['service__stats-item']}>
                            <span>{e.scheduled ? "Yes" : "No"}</span>
                        </div>
                        <div className={s['service__stats-item']}>
                            <span>{e.max_clients}</span>
                        </div>
                    </div>
                </div>))}
            </div>
        </div>
    );
};

export default ServicesBoard;