import React, {useState} from 'react';
import s from './Input.module.scss';
import classNames from "classnames";
import Eye from './../../../assets/images/icons/eye.svg';

interface IInput {
    icon?: string
    placeholder?: string
    label?: string
    isSmall?: boolean
    id?: string
    onChange?: any
    value?: string | number
    isPhone?: boolean
    phonePrefixes?: any
    isHideable?: boolean
    labelColor?: string
    type?: string
    name?: string
    disabled?: boolean
    error?: string | false | undefined
    sign?: string
}
const Input = ({icon, placeholder, label, isSmall, id, onChange, value, isPhone, phonePrefixes, isHideable, labelColor, type = 'text', name, disabled, error, sign}: IInput) => {

    let [isHidden, setIsHidden] = useState(true);

    return (
        <div className={classNames(s.input, label && s.input_labeled, isSmall && s.input_small)}>
            {label && <span style={{color: labelColor}} className={s.input__label}>{label}</span>}
            {icon && <img src={icon} alt="" className={s.input__icon}/>}
            <div className={s.input__wrapper}>
                {isPhone && <div className={s.select}>
                    <select defaultValue={phonePrefixes.filter((p: any) => p.selected)[0].prefix}>
                        {phonePrefixes.map((p: any) => (<option value={p.prefix} key={p.prefix}>{p.prefix}</option>))}
                    </select>
                </div>}
                {sign && <div className={s.sign}>
                    {sign}
                </div>}
                <input type={isHideable ? (isHidden ? 'password' : 'text') : type} placeholder={placeholder} id={id} name={name} disabled={disabled} value={value} onChange={onChange}/>
                {isHideable && <button className={s.input__hide} onClick={(e: any) => {
                    e.preventDefault();
                    setIsHidden(!isHidden);
                }}><img src={Eye} alt=""/></button>}
            </div>
            {error && <span className={s.input__error}>{error}</span>}
        </div>
    );
};

export default Input;