import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import Totals from "../../components/DashboardComponents/Totals/Totals";
import Blocks from "../../components/DashboardComponents/Blocks/Blocks";
import {backendAPI} from "../../api/backendAPI";
import Loader from "../../components/UI/Loader/Loader";

const Main = () => {
    let [data, setData] = useState<any>({});
    let [isLoading, setIsLoading] = useState(false);

    let fetchData = async () => {
        setIsLoading(true);
        setData(await backendAPI.fetchDashboard());
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Layout>
            {isLoading ? <Loader/> : <>
                <Totals totalBookings={data.total_lifetime_bookings} totalStaffMembers={data.total_pts} inviteCode={data.invite_code}/>
                {Object.keys(data).length > 0 && <Blocks data={data}/>}
            </>}
        </Layout>
    );
};

export default Main;