import React from 'react';
import s from './Blocks.module.scss';
import Analytics from "../../Analytics/Analytics";
import Block from "../../Block/Block";
import Unavailable from "../../Unavailable/Unavailable";
import Bookings from "../Bookings/Bookings";
import TopServices from "../TopServices/TopServices";

interface IBlocks {
    data: any
}
const Blocks = ({data}: IBlocks) => {
    return (
        <div className={s.blocks}>
            <Analytics analytics={data.business_analytics}/>
            <Block title={'Upcoming Bookings'}>
                {data.next_10_bookings && data.next_10_bookings.length ? <Bookings items={data.next_10_bookings}/> : <Unavailable title={"No upcoming bookings"} text={'Make some appointments for schedule\n' +
                    'data to appear'}/>}
            </Block>
            <Block title={'Bookings'}>
                {data.last_10_bookings && data.last_10_bookings.length ? <Bookings items={data.last_10_bookings}/> : <Unavailable title={"No last bookings"} text={'Make some appointments for schedule\n' +
                    'data to appear'}/>}
            </Block>
            <Block title={'Today’s Next Bookings'}>
                {data.today_bookings && data.today_bookings.length ? <Bookings items={data.today_bookings}/> : <Unavailable title={'No Today Bookings Available'} text={'Make some appointments for schedule data to appear'}/>}
            </Block>
            <Block title={'Top Services'}>
                <TopServices items={data.popular_events}/>
            </Block>
        </div>
    );
};

export default Blocks;