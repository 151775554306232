import React, {useState} from 'react';
import s from './PopupInfo.module.scss';
import Icon from './../../assets/images/icons/clock.svg';
import Photo from './../../assets/images/settings/photo.svg';
import Button from "../UI/Button/Button";
import Loader from "../UI/Loader/Loader";

interface IPopupInfo {
    popupTitle: string
    title: string
    subtitle: string
    image?: string
    data: any
    onEdit: any
    onDelete: any
    fetchData: any
    closePopup: any
    isLoading?: boolean
}
const PopupInfo = ({popupTitle, title, subtitle, image, data, onEdit, onDelete, fetchData, closePopup, isLoading}: IPopupInfo) => {

    let [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

    return (
        <div className={s.popup}>
            {isLoading ? <Loader/> : <><div className={s.popup__block}>
                <div className={s.popup__header}>
                    <div className={s['popup__header-icon']}>
                        <img src={Icon} alt=""/>
                    </div>
                    <span>{popupTitle} information</span>
                </div>
                <div className={s.popup__about}>
                    <div className={s['popup__about-photo']}>
                        <img src={image ? image : Photo} alt=""/>
                    </div>
                    <div className={s['popup__about-info']}>
                        <strong>{title}</strong>
                        {/*<p>Booked from <span>Melbourne, AUS.</span></p>*/}
                        <p>{subtitle}</p>
                    </div>
                </div>
            </div>
                {data && data.length && data.map((d: any) => (<div className={s.popup__block} key={d.title}>
                    <div className={s.popup__header}>
                        <div className={s['popup__header-icon']}>
                            <img src={Icon} alt=""/>
                        </div>
                        <span>{d.title}</span>
                    </div>
                    <div className={s.popup__info}>
                        {d.items.map((i: any) => (<div className={s['popup__info-item']} key={i.title}>
                            <p><span>{i.title}</span> {i.text}</p>
                            <button><img src={Icon} alt=""/></button>
                        </div>))}
                    </div>
                </div>))}
                {isDeleteConfirmation ? <>
                    <span>Are you sure you want to delete this {popupTitle.toLowerCase()}?</span>
                    <div className={s.popup__buttons}>
                        <Button text={`Confirm`} withBorder={true} onClick={onDelete}/>
                        <Button text={`No`} onClick={() => {setIsDeleteConfirmation(false)}}/>
                    </div>
                </> : <div className={s.popup__buttons}>
                    <Button text={`Edit ${popupTitle.toLowerCase()}`} withBorder={true} onClick={onEdit}/>
                    <Button text={`Delete ${popupTitle.toLowerCase()}`} onClick={() => {setIsDeleteConfirmation(true)}}/>
                </div>}</>}
        </div>
    );
};

export default PopupInfo;