import React, {useEffect, useState} from 'react';
import s from './DateSelection.module.scss';
import classNames from "classnames";
import Arrow from "../../../../assets/images/icons/arrow.svg";
import dayjs, {Dayjs} from "dayjs";
import {useScreenWidth} from "../../../../hooks/useScreenWidth";

interface IDateSelection {
    date: Dayjs

    onDateChange: (date: Dayjs) => void
}

const DateSelection = ({date, onDateChange}: IDateSelection) => {
    let [currentDate, setCurrentDate] = useState<Dayjs>(date);
    let [activeDate, setActiveDate] = useState<Dayjs>(date);
    let screenWidth = useScreenWidth();

    let isToday = (checkedDate: Dayjs) => {
        let today = dayjs();
        return checkedDate.get('D') === today.get('D') && checkedDate.get('M') === today.get('M') && checkedDate.get('y') === today.get('y');
    }

    let dates = [];
    for (let i = 0; i < 4; i++) {
        dates.push({i, date: currentDate.add(i, 'day')});
    }

    return (
        <div className={s.date}>
            <button className={classNames(s['date__arrow'], s['date__arrow_prev'])} onClick={() => {
                setCurrentDate(currentDate.subtract(1, 'day'));
            }}>
                <img src={Arrow} alt=""/>
            </button>
            {dates.map((d: any) => (
                <button className={classNames(s['date__item'], activeDate.isSame(d.date) && s['date__item_active'])} onClick={() => {
                    onDateChange(d.date);
                    setActiveDate(d.date);
                }} key={d.i}>
                    {isToday(d.date) ? <span>Today</span> : <><span>{d.date.format('D MMM')}</span><span>{screenWidth > 575 ? d.date.format('dddd') : d.date.format('ddd')}</span></>}
                </button>
            ))}
            <button className={classNames(s['date__arrow'], s['date__arrow_next'])} onClick={() => {
                setCurrentDate(currentDate.add(1, 'day'));
            }}>
                <img src={Arrow} alt=""/>
            </button>
        </div>
    );
};

export default DateSelection;