import React from 'react';
import s from './Unavailable.module.scss';
import CorruptedFile from './../../assets/images/icons/corrupted-file.svg';

interface IUnavailable {
    title: string
    text: string
}
const Unavailable = ({title, text}: IUnavailable) => {
    return (
        <div className={s.unavailable}>
            <img src={CorruptedFile} alt=""/>
            <strong>{title}</strong>
            <p>{text}</p>
        </div>
    );
};

export default Unavailable;