import React from 'react';
import Layout from "../../components/Layout/Layout";
import ProfileSettings from "../../components/ProfileSettings/ProfileSettings";

const Settings = () => {
    return (
        <Layout>
            <h1>Settings</h1>
            <ProfileSettings/>
        </Layout>
    );
};

export default Settings;