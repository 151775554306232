import React from 'react';
import s from './Select.module.scss';
import classNames from "classnames";
import {Field} from "formik";

interface ISelect {
    label?: string
    isSmall?: boolean
    name?: string
    children: any
}
const Select = ({label, isSmall, name, children}: ISelect) => {
    return (
        <div className={classNames(s.input, label && s.input_labeled, isSmall && s.input_small)}>
            {label && <span className={s.input__label}>{label}</span>}
            <div className={s.select}>
                <Field as="select" name={name}>
                    {children}
                </Field>
            </div>
        </div>
    );
};

export default Select;