import React, {useState} from 'react';
import Layout from "../../components/Layout/Layout";
import NewServiceComponent from "./../../components/NewService/NewService";
import Popup from "../../components/Popup/Popup";
import Schedule from "../../components/Schedule/Schedule";

const Signup = () => {
    let [isPopupActive, setIsPopupActive] = useState(true);

    return (
        <Layout>
            <Popup isActive={isPopupActive} closeHandle={() => {setIsPopupActive(false)}} title={'Select days to work'}>
                {/*<Schedule/>*/}
            </Popup>
            <NewServiceComponent/>
        </Layout>
    );
};

export default Signup;