import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import ServicesBoard from "../../components/ServicesBoard1/ServicesBoard";
import {backendAPI} from "../../api/backendAPI";
import Popup from "../../components/Popup/Popup";
import CreateMember from "../../components/CreateMember/CreateMember";
import PopupInfo from "../../components/PopupInfo/PopupInfo";
import toast from "react-hot-toast";
import Loader from "../../components/UI/Loader/Loader";
import Service from "./../../assets/images/services/service.jpg";

const Services = () => {
    let [data, setData] = useState<any>({});
    let [isLoading, setIsLoading] = useState(true);
    let [isPopupActive, setIsPopupActive] = useState<false | "edit" | "createEvent">(false);

    let [eventId, setEventId] = useState(0);
    let [editingEventData, setEditingEventData] = useState<any>({});
    let [eventInfo, setEventInfo] = useState<any>();
    let [isEventDataLoading, setIsEventDataLoading] = useState(false);

    let fetchEventData = async () => {
        setIsEventDataLoading(true);
        let response = await backendAPI.fetchEvent(eventId);
        setEditingEventData(response);
        setEventInfo([{
            title: "Event information",
            items: [{title: "Description", text: response.description},
                {title: "Max clients", text: response.max_clients},
                {title: "Price", text: "$" + response.price / 100},
                {title: "Length", text: response.length},]
        }]);
        setIsEventDataLoading(false);
    };

    let fetchData = async () => {
        setIsLoading(true);
        setData(await backendAPI.fetchServices());
        setIsLoading(false);
    };

    let handleUpdateEvent = async () => {
        setIsPopupActive("createEvent");
    };

    let handleDeleteEvent = async () => {
        try {
            let response = await backendAPI.deleteEvent(eventId);
            toast.success("Success");
            fetchData();
            setIsPopupActive(false);
        } catch (e) {
            toast.error("Error");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (eventId) {
            fetchEventData();
        }
    }, [eventId]);

    let closeHandle = () => {
        setIsPopupActive(false);
        setEventId(0);
    };

    return (
        <Layout>
            <Popup isActive={isPopupActive != false} closeHandle={closeHandle}>
                {isPopupActive == "createEvent" &&
                    <CreateMember closeHandle={closeHandle} fetchServices={fetchData} eventId={eventId}/>}
                {isPopupActive == "edit" && editingEventData &&
                    <PopupInfo popupTitle={"Event"} isLoading={isEventDataLoading} title={editingEventData.title}
                               subtitle={(editingEventData.grouped && editingEventData.scheduled) ? "Grouped & Scheduled" : (editingEventData.grouped ? "Grouped" : "Standart event")}
                               image={Service} data={eventInfo} onEdit={handleUpdateEvent} onDelete={handleDeleteEvent}
                               fetchData={fetchData} closePopup={closeHandle}/>}
            </Popup>
            <h1>Manage Services</h1>
            {/*<Settings/>*/}
            {isLoading ? <Loader/> : data.events && <ServicesBoard data={data} showCreatePopup={() => {
                setIsPopupActive("createEvent")
            }} showEditPopup={() => {
                setIsPopupActive("edit")
            }} setEditingEventId={setEventId} closePopup={() => {
                setIsPopupActive(false)
            }}/>}
        </Layout>
    );
};

export default Services;