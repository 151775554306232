import React, {useEffect} from 'react';
import s from './Layout.module.scss';
import Logo from './../../assets/images/logo.png';
import Dashboard from './../../assets/images/icons/dashboard.svg';
import Bell from './../../assets/images/icons/bell.svg';
import User from './../../assets/images/settings/photo.svg';
import classNames from "classnames";
import Sidebar from "../Sidebar/Sidebar";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import Button from "../UI/Button/Button";
import {NavLink, useNavigate} from "react-router-dom";

interface ILayout {
    children?: any
}
const Layout = ({children}: ILayout) => {

    let authData = useAppSelector(state => state.auth.data);
    let isAuth = useAppSelector(state => state.auth.data.isAuth);

    return (
        <div className={s.layout}>
            <Sidebar/>
            <div className={s.layout__block}>
                <header className={s.header}>
                    {authData.isAuth ? <>
                        <div className={s.header__user}>
                            <img src={User} alt=""/>
                            <span>{`${authData.firstName} ${authData.lastName}`}</span>
                        </div>
                    </> : <NavLink to={'/login'}><Button text={'Login'}/></NavLink>}
                </header>
                <div className={s.layout__content}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;