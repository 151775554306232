import React from 'react';
import s from './Schedule.module.scss';

interface ISchedule {
    times: Array<any>
}
const Schedule = ({times}: ISchedule) => {
    return (
        <div>
            {times.length ? <div>
                {times.length ? times.map((t: any) => (
                    <span key={t.start_time}>{t.start_time} - {t.end_time}</span>
                )) : null}
            </div> : null}
        </div>
    );
};

export default Schedule;