import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {setupStore} from "./store/store";
import reportWebVitals from './reportWebVitals';
import './index.scss';
import {BrowserRouter} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import App from "./App";

const container = document.getElementById('root')!;
const root = createRoot(container);
const store = setupStore();

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Toaster position={"top-center"} toastOptions={{style: {fontSize: 24}}}/>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
