import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import Settings from "../../components/Settings/Settings";
import StaffBoard from "../../components/StaffBoard/StaffBoard";
import Popup from "../../components/Popup/Popup";
import StaffPopup from "./../../components/Staff/Staff";
import {backendAPI} from "../../api/backendAPI";
import CreateTrainer from "../../components/CreateTrainer/CreateTrainer";
import Loader from "../../components/UI/Loader/Loader";
import PopupInfo from "../../components/PopupInfo/PopupInfo";
import toast from "react-hot-toast";
import Trainer from './../../assets/images/icons/trainer.png';
import StaffPopupInfo from "../../components/StaffPopupInfo/StaffPopupInfo";

const Staff = () => {
    let [data, setData] = useState({});
    let [isLoading, setIsLoading] = useState(true);
    let [popup, setPopup] = useState<false | "edit" | "createTrainer">(false);

    let [trainerId, setTrainerId] = useState(0);

    let showEditPopup = (id: number) => {
        setTrainerId(id);
        setPopup("edit");
    };

    let showCreateTrainerPopup = () => {
        setPopup("createTrainer");
    };

    let fetchData = async () => {
        setIsLoading(true);
        setData(await backendAPI.fetchStaffSchedule());
        setIsLoading(false);
    };

    let handleUpdateTrainer = async () => {
        setPopup("createTrainer");
    };

    let handleDeleteTrainer = async () => {
        try {
            let response = await backendAPI.deleteStaff(trainerId);
            toast.success("Success");
            fetchData();
            setPopup(false);
        } catch (e) {
            toast.error("Error");
        }
    };

    let closeHandle = () => {
        setPopup(false)
        setTrainerId(0);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Layout>
            <Popup isActive={popup != false} closeHandle={closeHandle}>
                {popup == "edit" && <StaffPopupInfo handleUpdateTrainer={handleUpdateTrainer} handleDeleteTrainer={handleDeleteTrainer} updateData={fetchData} closeHandle={closeHandle} trainerId={trainerId}/>}
                {popup == "createTrainer" && <CreateTrainer closeHandle={closeHandle} trainerId={trainerId}/>}
            </Popup>
            <h1>Manage Staff</h1>
            <Settings showPopup={showCreateTrainerPopup}/>
            {isLoading ? <Loader/> : Object.keys(data).length > 0 && <StaffBoard showEditPopup={showEditPopup} data={data}/>}
        </Layout>
    );
};

export default Staff;