import axios from "axios";
import {Location, NavigateFunction} from "react-router-dom";
import toast from "react-hot-toast";

const url = process.env.REACT_APP_API_URL;

export let instance = axios.create({
    baseURL: url,
    withCredentials: true,
})

instance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
});

function getCookie(name: string) {
    function escape(s: string) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}

export const setupInterceptors = (navigate: NavigateFunction, location: Location, checkAuth: any) => {
    instance.interceptors.response.use((response) => {
        return response
    }, async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                /*let refreshToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY4OTcwNDIzNSwiaWF0IjoxNjg5NjE3ODM1LCJqdGkiOiI3ZTRjZTk3YzdiOTc0OTE5YjA3OTU0ZGM1ZGE4MGFkNSIsInVzZXJfaWQiOjEyfQ.4mZWvcQOLUbXYWrFQ_k3zrjEsKciHnSlKpBEGor7ylY";*/
                // let refreshToken = getCookie("refresh-auth");
                let response = await instance.post(url + 'auth/token/refresh/', {withCredentials: true});
                localStorage.setItem('token', response.data.access);
                return instance.request(originalRequest);
            } catch (e) {
                console.log("some error");
            }
        } else if (error.response.status === 401 && !location.pathname.includes("/login") && localStorage.getItem('token') && error.config._isRetry) {
            checkAuth();
            localStorage.removeItem('token');
            navigate("/login");
        }
        if (error.response.status === 500) {
            toast.error("An unexpected error occurred. Please contact support");
        }
        return Promise.reject(error)
    })
}

export const authAPI = {
    async auth(){
        let response = await instance.get(url + 'auth/user/');
        return response.data;
    },
    async login(email: string, password: string){
        let response = await axios.post(url + 'auth/login/', {email, password}, {withCredentials: true});
        return response.data;
    },
    async signup(email: string, password: string, repeatPassword: string, firstName: string, lastName: string, phoneNumber: string, isTrainer: boolean, gymName: string){
        let response = await axios.post(url + 'auth/gym-register/', {email, password1: password, password2: repeatPassword, first_name: firstName, last_name: lastName, phone_number: phoneNumber, is_trainer: isTrainer, gym_name: gymName});
        return response.data;
    },
    async logout(){
        let response = await instance.post(url + 'auth/logout/');
        return response.data;
    },
    async forgotPassword(email: string){
        let response = await axios.post(url + 'auth/password/reset/', {email});
        return response.data;
    },
    async resetPassword(password: string, confirmPassword: string, uID: string, token: string){
        let response = await axios.post(url + 'auth/password/reset/confirm/', {new_password1: password, new_password2: confirmPassword, uid: uID, token});
        return response.data;
    },
}

export const backendAPI = {
    // MAIN
    async fetchDashboard(){
        let response = await instance.get('owner/dashboard/');
        return response.data;
    },
    // BOOKINGS
    async fetchReservations(date: string){
        let response = await instance.get(`reservations/date/${date}`);
        return response.data;
    },
    async fetchEvents(){
        let response = await instance.get('events?scheduled=true');
        return response.data;
    },
    async hostEvent(trainerId: number, eventId: number, startTime: string){
        let response = await instance.post('/reservations/create/', {
            trainer_id: trainerId,
            event_id: eventId,
            start_time: startTime,
        });
        return response.data;
    },
    async fetchReservation(id: number){
        let response = await instance.get(`/reservations/info/${id}`);
        return response.data;
    },
    async fetchTrainerInfo(id: number){
        let response = await instance.get(`trainers/info/${id}`);
        return response.data;
    },
    // MANAGE STAFF
    async fetchStaffSchedule(){
        let response = await instance.get('owner/staff-schedule');
        return response.data;
    },
    async fetchStaff(){
        let response = await instance.get('trainers');
        return response.data;
    },
    async createStaff(firstName: string, lastName: string, phone: string, email: string, password: string, passwordConfirm: string, schedule: any) {
        let response = await instance.post(`/trainers/create/`, {first_name: firstName, last_name: lastName, phone_number: phone, email, password1: password, password2: passwordConfirm, blacklisted_dates: [], availability: schedule, gym_id: 1});
        return response.data;
    },
    async deleteStaff(id: number) {
        let response = await instance.delete(`trainers/delete/${id}`);
        return response.data;
    },
    async updateStaff(id: number, firstName: string, lastName: string, phone: string, email: string, schedule: any) {
        let response = await instance.put(`trainers/update/${id}/`, {first_name: firstName, last_name: lastName, phone_number: phone, email, blacklisted_dates: [], availability: schedule, gym_id: 1});
        return response.data;
    },
    // MANAGE SERVICES
    async fetchServices(){
        let response = await instance.get('events');
        return response.data;
    },
    async fetchEvent(id: number){
        let response = await instance.get(`events/info/${id}`);
        return response.data;
    },
    async updateEvent(id: number, gymId: number, title: string, description: string, grouped: boolean, scheduled: boolean, maxClients: number, price: number, length: number, trainers: Array<any>){
        let response = await instance.put(`events/update/${id}/`, {blacklisted_dates: [], gym_id: 1, title, description, metadata: "{}", grouped, scheduled, max_clients: maxClients, price, length, allowed_trainers: trainers});
        return response.data;
    },
    async deleteEvent(id: number){
        let response = await instance.delete(`events/delete/${id}`);
        return response.data;
    },
    // TRAINERS
    async fetchTrainers(){
        let response = await instance.get('trainers');
        return response.data;
    },
    // EVENTS
    async createEvent(gymId: number, title: string, description: string, grouped: boolean, scheduled: boolean, maxClients: number, price: number, length: number, trainers: Array<any>){
        let response = await instance.post('events/create/', {blacklisted_dates: [], gym_id: 1, title, description, metadata: "{}", grouped, scheduled, max_clients: maxClients, price, length, allowed_trainers: trainers});
        return response.data;
    },
    // PAYMENT METHODS
    async fetchLinkStatus(){
        let response = await instance.get('/payments/link-status');
        return response.data;
    },
    async paymentOAuth(){
        let response = await instance.get('/payments/oauth');
        return response.data;
    },
    // NOTIFICATIONS
    async toggleNotifications(type: "email" | "sms", isToggle: boolean){
        let payload;
        if (type == "email") {
            payload = {"notifications.email": isToggle};
        } else {
            payload = {"notifications.sms": isToggle};
        }
        let response = await instance.post('/user/toggleable-settings', payload);
        return response.data;
    },
    // PROFILE
    async fetchProfileInfo(){
        let response = await instance.get('/user/profile/info');
        return response.data;
    },
    async updateProfileInfo(firstName: string, lastName: string, phone: string){
        let response = await instance.post('/user/profile/update', {first_name: firstName, last_name: lastName, phone_number: phone});
        return response.data;
    },
    async changePassword(password1: string, password2: string){
        let response = await instance.post('/auth/password/change/', {new_password1: password1, new_password2: password2});
        return response.data;
    },
}