import { createSlice } from '@reduxjs/toolkit'
import {auth, login, logout} from "../actionCreators";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        data: {
            isAuth: false,
            email: null,
            firstName: null,
            lastName: null,
        },
        isLoading: true,
        error: "",
    },
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(auth.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(auth.fulfilled, (state, action) => {
            state.data.email = action.payload.email;
            state.data.firstName = action.payload.first_name;
            state.data.lastName = action.payload.last_name;
            state.data.isAuth = true;
            state.isLoading = false;
        })
        builder.addCase(auth.rejected, (state, action) => {
            state.data.isAuth = false;
            localStorage.removeItem('token');
            state.isLoading = false;
        })

        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        })

        builder.addCase(login.fulfilled, (state, action) => {
            localStorage.setItem('token', action.payload.access);
            state.isLoading = false;
        })

        builder.addCase(login.rejected, (state, action) => {
            localStorage.removeItem('token');
            // @ts-ignore
            state.error = action.payload.non_field_errors[0];
            state.isLoading = false;
        })

        builder.addCase(logout.fulfilled, (state, action) => {
            state.data.isAuth = false;
            state.data.email = null;
            state.data.firstName = null;
            state.data.lastName = null;
            localStorage.removeItem('token');
        })
    },
})

export const {setIsLoading} = authSlice.actions

export default authSlice.reducer