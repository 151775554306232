import React, {useEffect, useState} from 'react';
import s from './CreateMember.module.scss';
import Step from "../UI/Step/Step";
import Clock from './../../assets/images/icons/clock.svg';
import {auth, login} from "../../store/actionCreators";
import Google from "../UI/Google/Google";
import Input from "../UI/Input/Input";
import Checkbox from "../UI/Checkbox/Checkbox";
import {Navigate, NavLink} from "react-router-dom";
import Button from "../UI/Button/Button";
import {Formik} from "formik";
import RadioCard from "../UI/RadioCard/RadioCard";
import ToggleButton from "../UI/ToggleButton/ToggleButton";
import Select from "../UI/Select/Select";
import TrainerSelect from "../NewService/components/TrainerSelect/TrainerSelect";
import {backendAPI} from "../../api/backendAPI";
import Textarea from "../UI/Textarea/Textarea";
import toast from "react-hot-toast";
import * as Yup from "yup";
import dayjs from "dayjs";
import Loader from "../UI/Loader/Loader";

interface ICreateMember {
    eventId?: number
    closeHandle: any
    fetchServices: any
}
const CreateMember = ({eventId = 0, closeHandle, fetchServices}: ICreateMember) => {

    let [step, setStep] = useState(0);
    let [trainers, setTrainers] = useState<any>();
    let [isLoading, setIsLoading] = useState(true);
    let [initialValues, setInitialValues] = useState({
        type: 'individual',
        title: '',
        description: '',
        duration: 0,
        maxClients: 1,
        price: 0,
        isConfirmed: false
    });

    let fetchData = async () => {
        setIsLoading(true);
        let fetchedTrainers = await backendAPI.fetchTrainers();
        fetchedTrainers.trainers.forEach((t: any) => t.selected = false);

        if (eventId !== 0) {
            let response = await backendAPI.fetchEvent(eventId);
            let length = response.length.split(':');

            setInitialValues({
                type: (response.grouped && response.scheduled) ? "groupedScheduled" : (!response.grouped && !response.scheduled ? "individual" : "scheduled"),
                title: response.title,
                description: response.description,
                duration: Number(length[0] * 60) + Number(length[1]),
                maxClients: response.max_clients,
                price: response.price / 100,
                isConfirmed: false
            });

            setTrainers(fetchedTrainers.trainers.map((t: any) => {
                if (response.allowed_trainers.includes(t.trainer_id)) {
                    return {...t, selected: true}
                }
                return t
            }));
        } else {
            setTrainers(fetchedTrainers.trainers);
        }
        setIsLoading(false);
    };

    let selectedTrainers: any = [];

    if (trainers) {
        selectedTrainers = trainers.filter((t: any) => t.selected);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const CreateMemberSchema = Yup.object().shape({
        title: Yup.string()
            .min(0)
            .max(255)
            .required(),
        description: Yup.string()
            .min(0)
            .max(1000)
            .required(),
        duration: Yup.number().min(0),
        maxClients: Yup.number().min(0),
        price: Yup.number().min(0),
    });

    if (isLoading) {
        return <Loader/>
    }

    return (
        <div className={s.create}>
            <div className={s.create__steps}>
                <Step title={"Step 1"} description={"Type"} icon={Clock} isActive={step === 0} isPrev={0 < step}
                      onClick={() => {
                          setStep(0)
                      }}/>
                <Step title={"Step 2"} description={"Information"} icon={Clock} isActive={step === 1} isPrev={1 < step}
                      onClick={() => {
                          setStep(1)
                      }}/>
                <Step title={"Step 3"} description={"Confirm"} icon={Clock} isActive={step === 2} isPrev={2 < step}
                      onClick={() => {
                          setStep(2)
                      }}/>
            </div>
            <Formik initialValues={initialValues} validationSchema={CreateMemberSchema}
                    onSubmit={async (values, {resetForm}) => {
                        if (selectedTrainers.length) {
                            try {
                                if (eventId == 0) {
                                    let response = await backendAPI.createEvent(0, values.title, values.description, values.type == "groupedScheduled", (values.type == "scheduled" || values.type == "groupedScheduled"), (values.type == "scheduled" || values.type == "individual") ? 1 : values.maxClients, values.price * 100, values.duration * 60, selectedTrainers.map((t: any) => t.trainer_id));
                                } else {
                                    let response = await backendAPI.updateEvent(eventId, 0, values.title, values.description, values.type == "groupedScheduled", (values.type == "scheduled" || values.type == "groupedScheduled"), (values.type == "scheduled" || values.type == "individual") ? 1 : values.maxClients, values.price * 100, values.duration * 60, selectedTrainers.map((t: any) => t.trainer_id));
                                }
                                toast.success("Success");
                                fetchServices();
                                closeHandle();
                                resetForm();
                            } catch (e: any) {
                                toast.error(`${Object.keys(e.response.data)[0]}: ${e.response.data[Object.keys(e.response.data)[0]][0]}`);
                            }
                        } else {
                            setStep(1);
                            toast("Select trainers please");
                        }
                    }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid
                      /* and other goodies */
                  }) => {
                    if (isSubmitting && (errors.title || errors.description || errors.duration || errors.maxClients || errors.price)) {
                        setStep(1);
                    }
                    return (
                        <form className={s.create__form} onSubmit={handleSubmit}>
                            {step === 0 && <>
                                <div className={s.create__radio}>
                                    <RadioCard name={"type"} value={"individual"} onChange={handleChange}
                                               checked={values.type === "individual"} icon={Clock} title={"Individual (1-1)"}
                                               description={"Test 1"}/>
                                    <RadioCard name={"type"} value={"scheduled"} onChange={handleChange}
                                               checked={values.type === "scheduled"} icon={Clock} title={"Scheduled"}
                                               description={"Test 1"}/>
                                    <RadioCard name={"type"} value={"groupedScheduled"} onChange={handleChange}
                                               checked={values.type === "groupedScheduled"} icon={Clock}
                                               title={"Grouped & Scheduled"} description={"Test 1"}/>
                                </div>
                            </>}
                            {step === 1 && <>
                                <div className={s.create__cols}>
                                    <div className={s['create__cols-item']}>
                                        <Input placeholder={'Enter event title'} label={'Event title'}
                                               value={values.title} onChange={handleChange} name={'title'}
                                               error={touched.title && errors.title}/>
                                        <Textarea placeholder={'Enter event description'}
                                                  label={'Event description'} value={values.description}
                                                  onChange={handleChange} name={'description'} type={'text'}
                                                  error={touched.description && errors.description}/>
                                        <Input placeholder={'Enter event duration'} label={'Event duration (mins.)'}
                                               value={values.duration.toString()} onChange={handleChange} name={'duration'}
                                               type={'number'} error={touched.duration && errors.duration}/>
                                    </div>
                                    <div className={s['create__cols-item']}>
                                        <TrainerSelect trainers={trainers} setTrainers={setTrainers}/>
                                        <Input placeholder={'Enter max clients'} label={'Max clients'}
                                               value={values.type == "scheduled" || values.type == "individual" ? 1 : values.maxClients} onChange={handleChange}
                                               name={'maxClients'} type={'number'}
                                               error={touched.maxClients && errors.maxClients} disabled={values.type == "scheduled" || values.type == "individual"}/>
                                        <Input sign={"$"} placeholder={'Enter price of event'} label={'Price of event'}
                                               value={values.price} onChange={handleChange} name={'price'}
                                               type={'number'} error={touched.price && errors.price}/>
                                    </div>
                                </div>
                            </>}
                            {step === 2 && <>
                                <div className={s.finish}>
                                    <span>Almost done! 🚀</span>
                                    <p>Confirm the event details and press confirm to create it.</p>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Type</td>
                                                <td>{values.type === "individual" && "Individual (1-1)"}
                                                    {values.type === "scheduled" && "Scheduled"}
                                                    {values.type === "groupedScheduled" && "Grouped & Scheduled"}</td>
                                            </tr>
                                            <tr>
                                                <td>Title</td>
                                                <td>{values.title}</td>
                                            </tr>
                                            <tr>
                                                <td>Duration</td>
                                                <td>{values.duration} mins.</td>
                                            </tr>
                                            <tr>
                                                <td>Trainers</td>
                                                <td>{selectedTrainers.map((t: any) => t.first_name + " " + t.last_name).join(", ")}</td>
                                            </tr>
                                            <tr>
                                                <td>Max clients</td>
                                                <td>{values.maxClients}</td>
                                            </tr>
                                            <tr>
                                                <td>Price</td>
                                                <td>${values.price}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ToggleButton checked={values.isConfirmed} name={"isConfirmed"} onChange={handleChange}
                                                  label={"I have confirmed the event details."}/>
                                </div>
                            </>}
                            <div className={s.create__buttons}>
                                <Button text={"Previous"} onClick={() => {
                                    setStep(step - 1)
                                }} isMedium={true} disabled={step === 0}/>
                                {step === 2 && <Button text={"Submit"} type={"submit"} disabled={!values.isConfirmed}
                                                      isMedium={true}/>}
                                {step < 2 && <Button text={"Next"} onClick={() => {
                                    setStep(step + 1)
                                }} isMedium={true}/>}
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default CreateMember;