import React, {useEffect, useState} from 'react';
import s from './PopupInfo.module.scss';
import Icon from './../../assets/images/icons/clock.svg';
import Photo from './../../assets/images/settings/photo.svg';
import Loader from "../UI/Loader/Loader";
import Calendar from "../../assets/images/icons/calendar_filled.svg";
import Clock from "../../assets/images/icons/clock.svg";
import dayjs from "dayjs";
import classNames from "classnames";
import {backendAPI} from "../../api/backendAPI";

interface IPopupInfo {
    popupTitle: string
    closePopup: any
    reservationId: number
}

const PopupInfo = ({popupTitle, closePopup, reservationId}: IPopupInfo) => {

    let [data, setData] = useState<any>();
    let [isLoading, setIsLoading] = useState(true);
    let [tab, setTab] = useState(1);

    let fetchData = async () => {
        setIsLoading(true);
        let response = await backendAPI.fetchReservation(reservationId);
        setData(response);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={s.popup}>
            {isLoading ? <Loader/> : data.event && data.clients && <>
                <div className={s.popup__block}>
                    <div className={s.popup__header}>
                        <div className={s['popup__header-icon']}>
                            <img src={Icon} alt=""/>
                        </div>
                        <span>{popupTitle} information</span>
                    </div>
                    <div className={s.popup__about}>
                        <div className={s['popup__about-photo']}>
                            <img src={Photo} alt=""/>
                        </div>
                        {data.event && <div className={s['popup__about-info']}>
                            <div className={s['popup__about-info__row']}>
                                <div className={s['date']}>
                                    <time>
                                        <img src={Calendar} alt=""/>
                                        <span>{dayjs(data.event.start_time).format("DD MMM, YYYY")}</span>
                                    </time>
                                    <time>
                                        <img src={Clock} alt=""/>
                                        <span>{dayjs(data.event.start_time).format("hh:MM A")}</span>
                                    </time>
                                </div>
                                <span>{data.event.length / 60} mins.</span>
                            </div>
                            <strong>{data.event.title}</strong>
                            <p>{data.event.trainer}</p>
                        </div>}
                    </div>
                </div>
                <div className={s.popup__block}>
                    <div className={s.popup__tabs}>
                        <button className={classNames(s.popup__header, s['popup__tabs-item'], tab === 1 && s['popup__tabs-item_active'])} onClick={() => {setTab(1)}}>
                            <div className={s['popup__header-icon']}>
                                <img src={Icon} alt=""/>
                            </div>
                            <span>Reservation information</span>
                        </button>
                        <button className={classNames(s.popup__header, s['popup__tabs-item'], tab === 2 && s['popup__tabs-item_active'])} onClick={() => {setTab(2)}}>
                            <div className={s['popup__header-icon']}>
                                <img src={Icon} alt=""/>
                            </div>
                            <span>Client information ({data.clients.length})</span>
                        </button>
                    </div>
                    {tab === 1 && <div className={s.popup__info}>
                        <div className={s['popup__info-item']}>
                            <p><span>Price</span> ${data.event.price / 100}</p>
                            <button><img src={Icon} alt=""/></button>
                        </div>
                        <div className={s['popup__info-item']}>
                            <p><span>Length</span> {data.event.length / 60} mins.</p>
                            <button><img src={Icon} alt=""/></button>
                        </div>
                    </div>}
                    {tab === 2 && <div className={s.popup__info}>
                        <div className={s.clients}>
                            <div className={s.clients__block}>
                                <span>{data.clients.length} {data.clients.length == 1 ? "client" : "clients"}</span>
                                <div className={s.clients__items}>
                                    {data.clients.map((i: any) => (<div className={s["clients__items-item"]} key={i.name + i.gym_name}>
                                        <div className={s["clients__items-item__photo"]}>
                                            <img src={Photo} alt=""/>
                                        </div>
                                        <div className={s["clients__items-item__info"]}>
                                            <strong>{i.name}</strong>
                                            <span>Email: {i.email} | Phone Number: {i.phone_number}</span>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                </>}
        </div>
    );
};

export default PopupInfo;