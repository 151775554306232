import React, {useEffect, useState} from 'react';
import s from './HostEvent.module.scss';
import Step from "../UI/Step/Step";
import Clock from './../../assets/images/icons/clock.svg';
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import {Formik} from "formik";
import ToggleButton from "../UI/ToggleButton/ToggleButton";
import {backendAPI} from "../../api/backendAPI";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Schedule from "../Schedule/Schedule";
import Loader from "../UI/Loader/Loader";
import TrainerSelect from "../NewService/components/TrainerSelect/TrainerSelect";
import dayjs from "dayjs";

interface ICreateTrainer {
    closeHandle: any
    updateData: any
}
const HostEvent = ({closeHandle, updateData}: ICreateTrainer) => {

    let [step, setStep] = useState(0);
    let [trainers, setTrainers] = useState<any>([]);
    let [events, setEvents] = useState<any>([]);
    let [isLoading, setIsLoading] = useState(true);
    let [initialValues, setInitialValues] = useState({
        date: dayjs().format('YYYY-MM-DD'),
        time: dayjs().format('HH:mm'),
        isConfirmed: false
    });

    let fetchData = async () => {
        setIsLoading(true);
        let response = await backendAPI.fetchEvents();
        response.events = response.events.map((e: any) => {
           let trainers = e.allowed_trainers.map((t: any, index: number) => ({...t, trainer_id: index}));
           return {...e, allowed_trainers: trainers};
        });
        setEvents(response.events.map((i: any, index: number) => index == 0 ? {...i, selected: true} : i));
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (events.length) {
            setTrainers(events.filter((e: any) => e.selected)[0].allowed_trainers);
        }
    }, [events]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <div className={s.create}>
            <div className={s.create__steps}>
                <Step title={"Host event"} description={"Enter details"} icon={Clock} isActive={step === 0} isPrev={0 < step}
                      onClick={() => {
                          setStep(0)
                      }}/>
                <Step title={"Confirmation"} description={"Confirm details"} icon={Clock} isActive={step === 1} isPrev={1 < step}
                      onClick={() => {
                          setStep(1)
                      }}/>
            </div>
            <Formik initialValues={initialValues}
                    onSubmit={async (values, {resetForm}) => {
                        try {
                            if (trainers.filter((t: any) => t.selected).length) {
                                let response = await backendAPI.hostEvent(trainers.filter((t: any) => t.selected)[0].user_id[0], events.filter((e: any) => e.selected)[0].event_id, dayjs(values.date + " " + values.time).toISOString());
                                toast.success("Success");
                                updateData();
                                closeHandle();
                                resetForm();
                            } else {
                                setStep(0);
                                toast.error("Select trainer please");
                            }
                        } catch (e: any) {
                            toast.error(`${Object.keys(e.response.data)[0]}: ${e.response.data[Object.keys(e.response.data)[0]][0]}`);
                        }
                    }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid
                      /* and other goodies */
                  }) => {
                    /*if (isSubmitting && (errors.firstName || errors.lastName || errors.email || errors.phone)) {
                        setStep(0);
                    }
                    if (isSubmitting && (errors.password || errors.passwordConfirm)) {
                        setStep(1);
                    }*/
                    return (
                        <form className={s.create__form} onSubmit={handleSubmit}>
                            {step === 0 && <div className={s.create__cols}>
                                <div className={s['create__cols-item']}>
                                    <div className={s['calendar__select-block__item']}>
                                        <div className={s['calendar__select-block__header']}>
                                            <span>Select Service</span>
                                            <div className={s['calendar__select-block__length']}>
                                                <img src={Clock} alt=""/>
                                                <span>{events.length && events.filter((e: any) => e.selected)[0].length / 60} mins.</span>
                                            </div>
                                        </div>
                                        <div className={s['calendar__service-wrapper']}>
                                            <select className={s['calendar__service']} onChange={(event) => {
                                                setEvents(events.map((e: any) => {
                                                    if (e.event_id == event.currentTarget.value) {
                                                        return {...e, selected: true};
                                                    }
                                                    return {...e, selected: false};
                                                }));
                                            }} defaultValue={events.length && events.filter((e: any) => e.selected)[0].event_id}>
                                                {events.map((s: any) => (<option value={s.event_id} key={s.event_id}>{s.title}</option>))}
                                            </select>
                                        </div>
                                        {/*<div className={s['calendar__select-block__desc']}>
                                            <button onClick={() => {}}>View description</button>
                                        </div>*/}
                                    </div>
                                    <TrainerSelect trainers={trainers} setTrainers={setTrainers} isSingle={true}/>
                                </div>
                                <div className={s['create__cols-item']}>
                                    <Input type={"date"} placeholder={'Enter date'} label={'Date'} onChange={handleChange} value={values.date} name={'date'} error={touched.date && errors.date}/>
                                    <Input type={"time"} placeholder={'Enter time'} label={'Time'} onChange={handleChange} value={values.time} name={'time'} error={touched.time && errors.time}/>
                                </div>
                            </div>}
                            {step === 1 && <div className={s.finish}>
                                <span>Almost done! 🚀</span>
                                <p>Confirm the session information and submit to schedule it.</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Event</td>
                                            <td>{events.filter((e: any) => e.selected)[0].title}</td>
                                        </tr>
                                        <tr>
                                            <td>Trainer</td>
                                            <td>{trainers.filter((t: any) => t.selected).length != 0 && trainers.filter((t: any) => t.selected)[0].name}</td>
                                        </tr>
                                        <tr>
                                            <td>Date</td>
                                            <td>{dayjs(values.date).format("DD/MM/YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <td>Time</td>
                                            <td>{values.time}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ToggleButton checked={values.isConfirmed} name={"isConfirmed"} onChange={handleChange}
                                              label={"I have confirmed the session details."}/>
                            </div>}
                            <div className={s.create__buttons}>
                                <Button text={"Previous"} onClick={() => {
                                    setStep(step - 1)
                                }} isMedium={true} disabled={step === 0}/>
                                {step === 1 && <Button text={"Submit"} type={"submit"} disabled={!values.isConfirmed}
                                                      isMedium={true}/>}
                                {step < 1 && <Button text={"Next"} onClick={() => {
                                    setStep(step + 1)
                                }} isMedium={true}/>}
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default HostEvent;