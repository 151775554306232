import React, {useState} from 'react';
import s from './Textarea.module.scss';
import classNames from "classnames";
import Eye from './../../../assets/images/icons/eye.svg';

interface IInput {
    icon?: string
    placeholder?: string
    label?: string
    isSmall?: boolean
    id?: string
    onChange?: any
    value?: string
    isPhone?: boolean
    isHideable?: boolean
    labelColor?: string
    type?: string
    name?: string
    disabled?: boolean
    error?: string | false | undefined
}
const Textarea = ({icon, placeholder, label, isSmall, id, onChange, value, isPhone, isHideable, labelColor, type = 'text', name, disabled, error}: IInput) => {
    return (
        <div className={classNames(s.input, label && s.input_labeled, isSmall && s.input_small)}>
            {label && <span style={{color: labelColor}} className={s.input__label}>{label}</span>}
            {icon && <img src={icon} alt="" className={s.input__icon}/>}
            <div className={s.input__wrapper}>
                <textarea id={id} name={name} rows={3} placeholder={placeholder} disabled={disabled} value={value} onChange={onChange}></textarea>
            </div>
            {error && <span className={s.input__error}>{error}</span>}
        </div>
    );
};

export default Textarea;