import React from 'react';
import s from './RadioCard.module.scss';
import {string} from "yup";

interface IRadioCard {
    name: string
    icon: string
    title: string
    description: string
    value: string
    onChange: any
    checked: boolean
}
const RadioCard = ({name, icon, title, description, value, onChange, checked}: IRadioCard) => {
    return (
        <label className={s.card}>
            <input type="radio" name={name} value={value} onChange={onChange} checked={checked}/>
            <div className={s.card__block}>
                <div className={s.card__icon}>
                    <img src={icon} alt=""/>
                </div>
                <span>{title}</span>
                <p>{description}</p>
                <div className={s.card__radio}>

                </div>
            </div>
        </label>
    );
};

export default RadioCard;