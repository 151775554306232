import React, {useState} from 'react';
import s from './../../ProfileSettings.module.scss';
import ToggleButton from "../../../UI/ToggleButton/ToggleButton";
import {backendAPI} from "../../../../api/backendAPI";
import toast from "react-hot-toast";

const Notifications = () => {
    let [isActiveEmail, setIsActiveEmail] = useState(false);
    let [isActiveSms, setIsActiveSms] = useState(false);

    let onChangeFirst = async () => {
        setIsActiveEmail(!isActiveEmail);
        let response = await backendAPI.toggleNotifications("email", !isActiveEmail);
        if (response.success) {
            toast.success("Success");
        } else {
            toast.error("error");
        }
    };

    let onChangeSecond = async () => {
        setIsActiveSms(!isActiveSms);
        let response = await backendAPI.toggleNotifications("sms", !isActiveSms);
        if (response.success) {
            toast.success("Success");
        } else {
            toast.error("error");
        }
    };

    return (
        <div className={s.settings__block}>
            <div className={s['settings__block-info']}>
                <h2>Notifications</h2>
            </div>
            <div className={s.others}>
                <a href="#" className={s.others__item}>
                    <div className={s['others__item-info']}>
                        <span>Email Notifications</span>
                        <p>Receive an automatic reminder to your email when a booking is near.</p>
                    </div>
                    <ToggleButton checked={isActiveEmail} onChange={onChangeFirst}/>
                </a>
                <a href="#" className={s.others__item}>
                    <div className={s['others__item-info']}>
                        <span>SMS Notifications</span>
                        <p>Receive an automatic text message when a booking is near.</p>
                    </div>
                    <ToggleButton checked={isActiveSms} onChange={onChangeSecond}/>
                </a>
            </div>
        </div>
    );
};

export default Notifications;