import React from 'react';
import s from './Checkbox.module.scss';

interface ICheckbox {
    text: string
    checked?: boolean
    name?: string
    onChange?: any
}
const Checkbox = ({text, checked, name, onChange}: ICheckbox) => {
    return (
        <label className={s.checkbox}>
            <input type="checkbox" checked={checked} name={name} onChange={(e) => onChange(e.currentTarget.checked)}/>
            <span className={s.checkbox__checkmark}></span>
            <span>{text}</span>
        </label>
    );
};

export default Checkbox;