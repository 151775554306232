import React from 'react';
import s from './BookingsBoard.module.scss';
import Clock from './../../assets/images/icons/clock.svg';
import BookingItem from "./components/BookingItem/BookingItem";
import Trainer from "../Trainer/Trainer";
import dayjs, {Dayjs} from "dayjs";

interface IBookingsBoard {
    showEditPopup: any
    data: any
}

const BookingsBoard = ({showEditPopup, data}: IBookingsBoard) => {
    let bookings: Array<any> = [];
    let startTime = dayjs();
    let endTime = dayjs();

    if (data && data.length) {
        startTime = dayjs(data[0].start_time);
        endTime = dayjs(data[0].end_time);
    }

    data && data.length && data.map((i: any) => {
        bookings.push(i.event.trainer);
        if (dayjs(i.start_time) < startTime) {
            startTime = dayjs(i.start_time);
        }
        if (dayjs(i.end_time) > endTime) {
            endTime = dayjs(i.end_time);
        }
    });

    let trainers = new Set(bookings);
    let trainersBookings: any = {};

    trainers.forEach((t) => {
        trainersBookings[t] = {items: []};
    });

    for (const trainersBookingsKey in trainersBookings) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].event.trainer == trainersBookingsKey) {
                trainersBookings[trainersBookingsKey].items.push(data[i]);
            }
        }
    }

    bookings = [];
    for (const trainersBookingsKey in trainersBookings) {
        bookings.push({trainer: trainersBookingsKey, items: trainersBookings[trainersBookingsKey].items});
    }
    startTime = startTime.minute(0).second(0);
    endTime = endTime.minute(0).second(0).hour(endTime.hour() + 1);

    let times: any = [];

    let firstTime = startTime;

    let i = 0;

    while (firstTime <= endTime && i < 24) {
        times.push(firstTime);
        firstTime = firstTime.hour(firstTime.hour() + 1);
        i++;
    }

    return (
        <div className={s.bookings}>
            <div></div>
            <div className={s.people}>
                {bookings.map((i) => <Trainer key={i.trainer} name={i.trainer}/>)}
            </div>
            <div className={s.time}>
                {times.map((t: any) => (<div key={t.format("HH:mm A")}>
                    <span>{t.format("HH:mm A")}</span>
                </div>))}
            </div>
            <div className={s.cols}>
                {bookings.map((r: any, index: number) => (
                    <div className={s.cols__item} key={index}>
                        {r.items.map((i: any) => <BookingItem
                            top={50 + 5 + (dayjs(i.start_time).diff(startTime, 'hour', true) * 100)}
                            height={dayjs(i.end_time).diff(i.start_time, 'hour', true) * 90}
                            showEditPopup={() => {showEditPopup(i.id)}} startTime={dayjs(i.start_time)}
                            endTime={dayjs(i.end_time)} title={i.event.title} key={i.id}/>)}
                        {times.map((t: any) => (<div className={s['cols__item-block']} key={t}></div>))}
                    </div>))}
            </div>
        </div>
    );
};

export default BookingsBoard;