import React from 'react';
import s from './Totals.module.scss';
import Calendar from './../../../assets/images/icons/calendar.svg';
import People from './../../../assets/images/icons/people.svg';
import { ReactComponent as Arrow } from './../../../assets/images/icons/arrow_big.svg';
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import Copy from "./../../../assets/images/icons/copy.svg";
import toast from "react-hot-toast";

interface ITotals {
    totalBookings: number
    totalStaffMembers: number
    inviteCode: string
}
const Totals = ({totalBookings, totalStaffMembers, inviteCode}: ITotals) => {
    let handleCopy = () => {
        toast.success("Successfuly copied");
        navigator.clipboard.writeText(inviteCode);
    };

    return (
        <div className={s.totals}>
            <NavLink to={'/bookings'}>
                <div className={classNames(s.totals__item, s.totals__item_pink)}>
                    <div className={s['totals__item-icon']} style={{background: '#D858AE'}}>
                        <img src={Calendar} alt=""/>
                    </div>
                    <div className={s['totals__item-info']}>
                        <strong>{totalBookings}</strong>
                        <span>Total Bookings</span>
                    </div>
                    <Arrow/>
                </div>
            </NavLink>
            <NavLink to={'/'}>
                <div className={s.totals__item}>
                    <div className={s['totals__item-icon']} style={{background: '#4F69C1'}}>
                        <img src={People} alt=""/>
                    </div>
                    <div className={s['totals__item-info']}>
                        <strong>{totalStaffMembers}</strong>
                        <span>Total Staff</span>
                    </div>
                    <Arrow/>
                </div>
            </NavLink>
            <div className={classNames(s.totals__item, s.totals__share)}>
                <span>Invite your clients via this link</span>
                <div className={s.copy}>
                    <input type="text" disabled={true} value={inviteCode}/>
                    <button onClick={handleCopy}><img src={Copy} alt="Copy"/></button>
                </div>
            </div>
        </div>
    );
};

export default Totals;