import React from 'react';
import s from './TopServices.module.scss';
import {useScreenWidth} from "../../../hooks/useScreenWidth";

interface ITopServices {
    items: Array<any>
}
const TopServices = ({items}: ITopServices) => {
    let screenWidth = useScreenWidth();
    return (
        <div className={s.services}>
            <div className={s.services__table}>
                {screenWidth > 768 ? <table cellPadding={0} cellSpacing={0}>
                    <thead>
                    <tr>
                        <th>Service Name</th>
                        <th>This Month</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((i: any) => (<tr key={i.event_id}>
                        <td>{i.event_name}</td>
                        <td>{i.total}</td>
                    </tr>))}
                    </tbody>
                </table> : <table cellPadding={0} cellSpacing={0}>
                    <thead>
                    <tr>
                        <th>Service Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((i: any) => (<tr key={i.event_id}>
                        <td>
                            <div className={s["services__table-row"]}>
                                <span>{i.event_name}</span>
                                <span>{i.total}</span>
                            </div>
                        </td>
                    </tr>))}
                    </tbody>
                </table>}
            </div>
        </div>
    );
};

export default TopServices;