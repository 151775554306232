import React, {useEffect, useState} from 'react';
import s from './../../ProfileSettings.module.scss';
import Stripe from "../../../../assets/images/icons/stripe.svg";
import Button from "../../../UI/Button/Button";
import {backendAPI} from "../../../../api/backendAPI";
import Loader from "../../../UI/Loader/Loader";

const PaymentMethods = () => {
    let [data, setData] = useState<any>({});
    let [url, setUrl] = useState<string>();
    let [isLoading, setIsLoading] = useState(false);

    let fetchData = async () => {
        setIsLoading(true);
        setData(await backendAPI.fetchLinkStatus());
        let urlResponse = await backendAPI.paymentOAuth();
        setUrl(urlResponse.url);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    let handleClick = async () => {
        // @ts-ignore
        window.open(url, '_blank').focus();
    };

    return (
        <div className={s.settings__block}>
            <div className={s['settings__block-info']}>
                <h2>Payment Methods</h2>
                <span>Enable or disable payment methods for your business account.</span>
            </div>
            {isLoading ? <Loader/> : <div className={s.socials}>
                <div className={s.socials__item}>
                    <div className={s['socials__item-info']}>
                        <div className={s['socials__item-info__icon']}>
                            <img src={Stripe} alt=""/>
                        </div>
                        <div className={s['socials__item-info__info']}>
                            <span>Stripe</span>
                            {data.status == "Linked" ?
                                <span>Connected</span> :
                                <span>Unconnected</span>}
                        </div>
                    </div>
                    {data.status == "Linked" ?
                        <Button text={'Dashboard'} withBorder={true} isWhite={true} onClick={handleClick}/> :
                        <Button text={'Connect'} withBorder={true} onClick={handleClick}/>}
                </div>
            </div>}
        </div>
    );
};

export default PaymentMethods;