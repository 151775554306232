import React, {useState} from 'react';
import s from "../../Auth.module.scss";
import {Formik, FormikValues} from "formik";
import Google from "../../../UI/Google/Google";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import {NavLink, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useAppDispatch} from "../../../../store/hooks";
import {authAPI} from "../../../../api/backendAPI";
import toast from "react-hot-toast";
import Select from "../../../UI/FormikSelect/Select";
import {auth, login} from "../../../../store/actionCreators";

const Signup = () => {
    let dispatch = useAppDispatch();
    let navigate = useNavigate();
    let [step, setStep] = useState(0);

    const signupSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('Email is required'),
        password: Yup.string().min(8).required('Password is required'),
        confirmPassword: Yup.string()
            .required('Required').min(8)
            .oneOf([Yup.ref('password')], 'Passwords must match'),
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required')
    });

    let handleSubmit = async (values: FormikValues) => {
        try {
            await authAPI.signup(values.email, values.password, values.confirmPassword, values.firstName, values.lastName, values.phone, values.role === "personalTrainer", values.gym);
            dispatch(login({email: values.email, password: values.password})).then(() => {
                if (localStorage.getItem('token')) {
                    dispatch(auth());
                }
            });
            toast.success("Signup was successful");
            navigate("/");
        } catch (e: any) {
            toast.error(e.response.data[Object.keys(e.response.data)[0]][0]);
        }
    }

    return (
        <div className={s['auth__block-container']}>
            <div className={s['auth__block-info']}>
                <h2>Create an Account</h2>
                {/*<p>Let’s get started with your 30 day free trial</p>*/}
            </div>
            <Formik initialValues={{email: '', password: '', confirmPassword: '', firstName: '', lastName: '', phone: '', gym: '', role: 'personalTrainer' }}
                    validationSchema={signupSchema}
                    onSubmit={handleSubmit}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form className={s['auth__block-form']} onSubmit={handleSubmit}>
                        {step === 0 && <>
                            {/*<Google text={'Signup with Google'} className={s['auth__block-form__google_signup']}/>
                            <div className={s['auth__block-form__or']}>
                                <span>or</span>
                            </div>*/}
                            <div className={s['auth__block-form__row']}>
                                <Input placeholder={'Enter first name'} label={'First Name'} value={values.firstName} onChange={handleChange} name={'firstName'} error={touched.firstName && errors.firstName}/>
                                <Input placeholder={'Enter last name'} label={'Last Name'} value={values.lastName} onChange={handleChange} name={'lastName'} error={touched.lastName && errors.lastName}/>
                            </div>
                            <Input placeholder={'Enter your email'} label={'Email'} value={values.email} onChange={handleChange} name={'email'} error={touched.email && errors.email}/>
                            <Input placeholder={'Enter your password'} label={'Password'} value={values.password} onChange={handleChange} name={'password'} type={'password'} error={touched.password && errors.password}/>
                            <Input placeholder={'Confirm password'} label={'Password'} value={values.confirmPassword} onChange={handleChange} name={'confirmPassword'} type={'password'} error={touched.confirmPassword && errors.confirmPassword}/>
                            <Button text={'Next'} isBig={true} onClick={() => {setStep(1)}}/>
                        </>}
                        {step == 1 && <>
                            <Input placeholder={'Enter your phone'} label={'Phone number'} value={values.phone} onChange={handleChange} name={'phone'} error={touched.phone && errors.phone}/>
                            <Input placeholder={'Enter gym name'} label={'Gym name'} value={values.gym} onChange={handleChange} name={'gym'} error={touched.gym && errors.gym}/>
                            <Select name={"role"} label={"Select your role"} isSmall={true}>
                                <option value="personalTrainer">Personal trainer</option>
                                <option value="gymOwner">Gym owner</option>
                            </Select>
                            <div className={s["auth__block-form__buttons"]}>
                                <Button text={'Back'} onClick={() => {setStep(0)}}/>
                                <Button text={'Create account'} type={"submit"} disabled={isSubmitting} onClick={handleSubmit}/>
                            </div>
                        </>}
                    </form>
                )}
            </Formik>
            <div className={s['auth__block-link']}>
                <span>Already have an account? <NavLink to={'/login'}>Login</NavLink></span>
            </div>
        </div>
    );
};

export default Signup;