import React from 'react';
import s from './Settings.module.scss';
import Arrow from './../../assets/images/icons/arrow.svg';
import classNames from "classnames";
import {Dayjs} from "dayjs";
import DateSelection from "./components/DateSelection/DateSelection";
import Button from "../UI/Button/Button";

interface ISettings {
    date?: Dayjs
    showHostEventPopup?: () => void
    onDateChange?: (date: Dayjs) => void
    withFilters?: boolean
    showPopup?: () => void
}

const Settings = ({date, onDateChange, showHostEventPopup, withFilters = true, showPopup}: ISettings) => {
    return (
        <div className={s.settings}>
            {showHostEventPopup && <Button text={"Host event"} onClick={showHostEventPopup}/>}
            {(date && onDateChange) && <DateSelection date={date} onDateChange={onDateChange}/>}
            {withFilters && <div className={s.filters}>
                {/*<div className={s.filters__select}>
                    <select>
                        <option value="Working">Working</option>
                    </select>
                </div>
                <div className={s.filters__select}>
                    <select>
                        <option value="Working">Daily</option>
                    </select>
                </div>
                <div className={s.filters__select}>
                    <select>
                        <option value="Working">More</option>
                    </select>
                </div>*/}
                <button className={s.filters__button} onClick={showPopup}>
                    <span>+ Add Member</span>
                </button>
            </div>}
        </div>
    );
};

export default Settings;