import React, {useEffect, useState} from 'react';
import s from './CreateTrainer.module.scss';
import Step from "../UI/Step/Step";
import Clock from './../../assets/images/icons/clock.svg';
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import {Formik} from "formik";
import ToggleButton from "../UI/ToggleButton/ToggleButton";
import {backendAPI} from "../../api/backendAPI";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Schedule from "../Schedule/Schedule";
import Loader from "../UI/Loader/Loader";

interface ICreateTrainer {
    closeHandle: any
    trainerId?: number
}
const CreateTrainer = ({closeHandle, trainerId = 0}: ICreateTrainer) => {

    let [step, setStep] = useState(0);
    let [schedule, setSchedule] = useState<any>([
        {day: "Monday", times: []},
        {day: "Tuesday", times: []},
        {day: "Wednesday", times: []},
        {day: "Thursday", times: []},
        {day: "Friday", times: []},
        {day: "Saturday", times: []},
        {day: "Sunday", times: []},
    ]);
    let [isLoading, setIsLoading] = useState(true);
    let [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        passwordConfirm: "",
        isConfirmed: false
    });

    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const CreateMemberSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(0)
            .max(255)
            .required(),
        lastName: Yup.string()
            .min(0)
            .max(255)
            .required(),
        email: Yup.string()
            .min(0)
            .max(255)
            .required()
            .email(),
        phone: Yup.string()
            .min(0)
            .max(255)
            .matches(phoneRegExp, "Enter valid phone"),
        password: Yup.string()
            .min(0)
            .max(255)
            .required(),
        passwordConfirm: Yup.string()
            .min(0)
            .max(255)
            .required()
            .oneOf([Yup.ref("password")], "Passwords don't match")
    });

    let fetchData = async () => {
        setIsLoading(true);
        if (trainerId != 0) {
            let response = await backendAPI.fetchTrainerInfo(trainerId);
            let fetchedSchedule: any = [];
            let availability: any = {
                "Monday": [],
                "Tuesday": [],
                "Wednesday": [],
                "Thursday": [],
                "Friday": [],
                "Saturday": [],
                "Sunday": [],
            };
            for (const availabilityKey in response.availability) {
                response.availability[availabilityKey] && response.availability[availabilityKey].map((t: any) => {
                    let times = t.split("-");
                    availability[availabilityKey].push({start: times[0], end: times[1]});
                });
            }
            for (const availabilityKey in availability) {
                fetchedSchedule.push({day: availabilityKey, times: availability[availabilityKey]})
            }
            setSchedule(fetchedSchedule);
            setInitialValues({
                firstName: response.contact_details.first_name,
                lastName: response.contact_details.last_name,
                email: response.contact_details.email,
                phone: response.contact_details.phone_number,
                password: "password",
                passwordConfirm: "password",
                isConfirmed: false
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <div className={s.create}>
            <div className={s.create__steps}>
                <Step title={"Basic Info"} description={"Enter details"} icon={Clock} isActive={step === 0} isPrev={0 < step}
                      onClick={() => {
                          setStep(0)
                      }}/>
                {trainerId == 0 && <Step title={"Password Setup"} description={"Enter password"} icon={Clock} isActive={step === 1} isPrev={1 < step}
                                         onClick={() => {
                                             setStep(1)
                                         }}/>}
                <Step title={"Schedule"} description={"Set schedule"} icon={Clock} isActive={step === 2} isPrev={2 < step}
                      onClick={() => {
                          setStep(2)
                      }}/>
                <Step title={"Confirmation"} description={"Confirm details"} icon={Clock} isActive={step === 3} isPrev={3 < step}
                      onClick={() => {
                          setStep(3)
                      }}/>
            </div>
            <Formik initialValues={initialValues} validationSchema={CreateMemberSchema}
                    onSubmit={async (values, {resetForm}) => {
                        try {
                            let requestSchedule: any = {};
                            schedule.map((d: any) => {
                                let scheduleAvailability: any = [];
                                d.times.map((t: any) => {
                                    scheduleAvailability.push(`${t.start}-${t.end}`);
                                });
                                requestSchedule[d.day] = scheduleAvailability;
                            });
                            if (trainerId == 0) {
                                let response = await backendAPI.createStaff(values.firstName, values.lastName, values.phone, values.email, values.password, values.passwordConfirm, requestSchedule);
                            } else {
                                let response = await backendAPI.updateStaff(trainerId, values.firstName, values.lastName, values.phone, values.email, requestSchedule);
                            }
                            toast.success("Success");
                            closeHandle();
                            resetForm();
                        } catch (e: any) {
                            toast.error(`${Object.keys(e.response.data)[0]}: ${e.response.data[Object.keys(e.response.data)[0]][0]}`);
                        }
                    }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid
                      /* and other goodies */
                  }) => {
                    if (isSubmitting && (errors.firstName || errors.lastName || errors.email || errors.phone)) {
                        setStep(0);
                    }
                    if (isSubmitting && (errors.password || errors.passwordConfirm)) {
                        setStep(1);
                    }
                    return (
                        <form className={s.create__form} onSubmit={handleSubmit}>
                            {step === 0 && <div className={s.create__cols}>
                                <div className={s['create__cols-item']}>
                                    <Input placeholder={'Enter first name'} label={'First name'}
                                           value={values.firstName} onChange={handleChange} name={'firstName'}
                                           error={touched.firstName && errors.firstName}/>
                                    <Input placeholder={'Enter last name'} label={'Last name'}
                                           value={values.lastName} onChange={handleChange} name={'lastName'}
                                           error={touched.lastName && errors.lastName}/>
                                </div>
                                <div className={s['create__cols-item']}>
                                    <Input placeholder={'Enter email'} label={'Email'}
                                           value={values.email} onChange={handleChange} name={'email'}
                                           error={touched.email && errors.email}/>
                                    <Input placeholder={'Enter phone'} label={'Phone'}
                                           value={values.phone} onChange={handleChange} name={'phone'}
                                           error={touched.phone && errors.phone}/>
                                </div>
                            </div>}
                            {step === 1 && <>
                                <p>This password will be used to login to the staff account.</p>
                                <div className={s.create__cols}>
                                    <div className={s['create__cols-item']}>
                                        <Input placeholder={'Enter password'} label={'Password'}
                                               value={values.password} onChange={handleChange} name={'password'} type={"password"}
                                               error={touched.password && errors.password}/>
                                    </div>
                                    <div className={s['create__cols-item']}>
                                        <Input placeholder={'Enter confirm password'} label={'Confirm Password'}
                                               value={values.passwordConfirm} onChange={handleChange} name={'passwordConfirm'} type={"password"}
                                               error={touched.passwordConfirm && errors.passwordConfirm}/>
                                    </div>
                                </div>
                            </>}
                            {step === 2 && <>
                                <Schedule schedule={schedule} setSchedule={setSchedule}/>
                            </>}
                            {step === 3 && <div className={s.finish}>
                                <span>Almost done! 🚀</span>
                                <p>Confirm staff details information and submit to create the account.</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{`${values.firstName} ${values.lastName}`}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{values.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{values.phone}</td>
                                        </tr>
                                        {trainerId == 0 && <tr>
                                            <td>Password</td>
                                            <td>{values.password}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                                <ToggleButton checked={values.isConfirmed} name={"isConfirmed"} onChange={handleChange}
                                              label={"I have confirmed the staff details."}/>
                            </div>}
                            <div className={s.create__buttons}>
                                <Button text={"Previous"} onClick={() => {
                                    if (trainerId != 0 && step == 2) {
                                        setStep(0);
                                    } else {
                                        setStep(step - 1)
                                    }
                                }} isMedium={true} disabled={step === 0}/>
                                {step === 3 && <Button text={"Submit"} type={"submit"} disabled={!values.isConfirmed}
                                                      isMedium={true}/>}
                                {step < 3 && <Button text={"Next"} onClick={() => {
                                    if (trainerId != 0 && step == 0) {
                                        setStep(2);
                                    } else {
                                        setStep(step + 1)
                                    }
                                }} isMedium={true}/>}
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default CreateTrainer;