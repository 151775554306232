import React, {useEffect, useState} from 'react';
import s from './BookingItem.module.scss';
import Clock from "../../../../assets/images/icons/clock.svg";
import {ReactComponent as Edit} from "./../../../../assets/images/icons/edit.svg";
import {backendAPI} from "../../../../api/backendAPI";
import {Dayjs} from "dayjs";

interface IBookingItem {
    top: number
    height: number
    showEditPopup: () => void
    startTime: Dayjs
    endTime: Dayjs
    title: string
}
const BookingItem = ({top, height, showEditPopup, startTime, endTime, title}: IBookingItem) => {
    return (
        <div className={s.booking} style={{top: top, height: height}} onClick={showEditPopup}>
            {/*<div className={s.booking__buttons}>
                <button onClick={showEditPopup}>
                    <Edit/>
                </button>
                <button>
                    <Edit/>
                </button>
            </div>*/}
            <span>{title}</span>
            <div className={s.booking__time}>
                <img src={Clock} alt=""/>
                <span>{startTime.format("HH:mm A")} - {endTime.format("HH:mm A")}</span>
            </div>
        </div>
    );
};

export default BookingItem;