import React from 'react';
import s from './Bookings.module.scss';
import Service from './../../../assets/images/bookings/service.svg';
import Clock from './../../../assets/images/icons/clock.svg';
import Calendar from './../../../assets/images/icons/calendar_filled.svg';
import BookingsItem from "./components/BookingsItem/BookingsItem";

interface IBookings {
    items: Array<any>
}
const Bookings = ({items}: IBookings) => {
    return (
        <div className={s['bookings']}>
            {items.map((i) => (<BookingsItem booking={i} key={i.id}/>))}
        </div>
    );
};

export default Bookings;