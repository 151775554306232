import React, {ReactNode, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {auth} from "./store/actionCreators";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Main from "./pages/Main/Main";
import Bookings from "./pages/Bookings/Bookings";
import Staff from "./pages/Staff/Staff";
import Settings from "./pages/Settings/Settings";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import NewService from "./pages/NewService/NewService";
import Services from "./pages/Services/Services";
import {setIsLoading} from "./store/reducers/authSlice";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import {setupInterceptors} from "./api/backendAPI";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import Loader from "./components/UI/Loader/Loader";
import AuthComponent from "./components/AuthComponent/AuthComponent";

const App = () => {
    const dispatch = useAppDispatch();
    const authData = useAppSelector(state => state.auth);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(auth());
        } else {
            dispatch(setIsLoading(false));
        }
        setupInterceptors(navigate, location, () => {
            dispatch(auth())
        });
    }, []);

    let pages = [
        {
            path: "/",
            element: <Main/>,
        },
        {
            path: "/bookings",
            element: <Bookings/>,
        },
        {
            path: "/staff",
            element: <Staff/>,
        },
        {
            path: "/settings",
            element: <Settings/>,
        },
        {
            path: "/signup",
            element: <Signup/>,
            withoutAuth: true,
        },
        {
            path: "/login",
            element: <Login/>,
            withoutAuth: true,
        },
        {
            path: "/forgot-password",
            element: <ForgotPassword/>,
            withoutAuth: true,
        },
        {
            path: "/auth/password/reset/confirm/:uID/:token",
            element: <PasswordReset/>,
            withoutAuth: true
        },
        {
            path: "/new-service",
            element: <NewService/>,
        },
        {
            path: "/services",
            element: <Services/>,
        },
    ];

    if (authData.isLoading) {
        return <Loader isFull={true}/>;
    }

    return (
        <Routes>
            {pages.map((p: {path: string, element: ReactNode, withoutAuth?: boolean}) => (<Route path={p.path} element={p.withoutAuth ? p.element : <AuthComponent>{p.element}</AuthComponent>} key={p.path}/>))}
        </Routes>
    );
};

export default App;