import React, {useEffect, useState} from 'react';
import s from './Trainer.module.scss';
import Photo from "../../assets/images/header/user.png";
import {backendAPI} from "../../api/backendAPI";

interface ITrainer {
    name: string
}
const Trainer = ({name}: ITrainer) => {
    return (
        <div className={s['trainer']}>
            <img src={Photo} alt=""/>
            <span>{name}</span>
        </div>
    );
};

export default Trainer;