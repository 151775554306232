import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import Settings from "../../components/Settings/Settings";
import BookingsBoard from "../../components/BookingsBoard/BookingsBoard";
import {Routes} from "react-router-dom";
import Popup from "../../components/Popup/Popup";
import PopupInfo from "../../components/PopupInfo/PopupInfo";
import dayjs, {Dayjs} from "dayjs";
import {backendAPI} from "../../api/backendAPI";
import Unavailable from "../../components/Unavailable/Unavailable";
import Loader from "../../components/UI/Loader/Loader";
import PopupReservationInfo from "../../components/PopupInfo/PopupReservationInfo";
import HostEvent from "../../components/HostEvent/HostEvent";

const Bookings = () => {
    let [data, setData] = useState<any>();
    let [isLoading, setIsLoading] = useState(true);
    let [popup, setPopup] = useState<"reservation" | "hostEvent" | false>(false);

    let [date, setDate] = useState(dayjs());
    let [reservationId, setReservationId] = useState<number>(0);

    let showEditPopup = async (id: number) => {
        setPopup("reservation");
        setReservationId(id);
    };

    let fetchData = async () => {
        setIsLoading(true);
        setData(await backendAPI.fetchReservations(date.format("YYYY-MM-DD")));
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [date]);

    return (
        <Layout>
            <Popup isActive={popup != false} closeHandle={() => {
                setPopup(false);
            }}>
                {popup == "reservation" && <PopupReservationInfo popupTitle={"Reservation"} reservationId={reservationId} closePopup={() => {
                    setPopup(false)
                }}/>}
                {popup == "hostEvent" && <HostEvent closeHandle={() => {
                    setPopup(false);
                }} updateData={fetchData}/>}
            </Popup>
            <h1>Gym Bookings</h1>
            <Settings date={date} showHostEventPopup={() => {setPopup("hostEvent")}} onDateChange={(date: Dayjs) => {
                setDate(date)
            }} withFilters={false}/>
            {isLoading ? <Loader/> : data.length > 0 ?
                    <BookingsBoard showEditPopup={(id: number) => {showEditPopup(id)}} data={data}/> : <Unavailable title={"No bookings"} text={'Make some appointments for schedule\n' +
                        'data to appear'}/>}
        </Layout>
    );
};

export default Bookings;